import PageLayout from "components/shared/pageLayout/PageLayout";
import Home from "components/home/Home";

export default function Index() {
    return (
        <PageLayout slug="index">
            <Home />
        </PageLayout>
    );
}
