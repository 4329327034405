import Acceptants from "components/shared/acceptants/Acceptants";
import HomeHero from "./homeHero/HomeHero";
import Usps from "./usps/Usps";
import Steps from "./steps/Steps";
import Seo from "./seo/Seo";
import Merchants from "./merchants/Merchants";
import Gift from "./gift/Gift";
import Shops from "./shops/Shops";
import Double from "./double/Double";

import { Above } from "./Home.styled";

export default function Home() {
    return (
        <>
            <HomeHero />
            <Merchants isHomepage />
            <Above>
                <Usps />
                <Steps />
                <Acceptants
                    title="Heb je een Lees! Cadeaukaart ontvangen?"
                    description="Gefeliciteerd! Je kunt je gift card spenderen aan verschillende soorten tijdschriften, boeken en e-books. Hier zie je al een aantal populaire tijdschrijften, boeken en e-books. Veel leesplezier! Deze kun je bij onderstaande offline en online winkels aanschaffen."
                    buttons="redeem"
                />
                <Gift />
            </Above>
            <Shops />
            <Double />
            <Seo />
        </>
    );
}
